<template>
  <span
    class="text-container px-1"
    :style="{ width: content_width + 'px !important' }"
  >
    <template v-if="text.length + drop_text.length < large">
      <span class="rich-text-content" v-html="text"></span>
      <div class="drop-text-container pt-1" v-if="drop_text">
        {{ drop_text }}
      </div>
    </template>
    <template v-else>
      <span v-if="!showText">
        <div
          class="bounded-text rich-text-content"
          v-html="text.substring(0, large).trim()"
        ></div>
        <div class="text-center">...</div>
        <b-icon
          icon="chevron-down"
          class="cursor-pointer mt-2"
          @click="showText = !showText"
          v-b-tooltip.top.v-secondary.noninteractive="'Click para ver más'"
        ></b-icon>
      </span>
      <template v-else>
        <span class="rich-text-content" v-html="text"></span>
        <div class="drop-text-container pt-1" v-if="drop_text">
          {{ drop_text }}
        </div>
        <b-icon
          icon="chevron-up"
          class="cursor-pointer"
          @click="showText = !showText"
          v-b-tooltip.top.v-secondary.noninteractive="'Click para ver menos'"
        ></b-icon>
      </template>
    </template>
  </span>
</template>
<script>
export default {
  name: "BoundedRichText",
  props: {
    text: {
      type: String,
      required: true,
    },
    drop_text: {
      type: String,
      default: "",
    },
    large: {
      type: Number,
      default: 100,
    },
    // tooltip_text: {
    //   type: String,
    //   required: true,
    // },
    content_width: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      showText: false,
    };
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style>
.text-container {
  /* width: max-content !important; */
  word-break: break-word;
  text-overflow: ellipsis;
  animation: 1s ease-in-out;
}
.icon-class {
  vertical-align: bottom !important;
}
.cursor-help {
  cursor: help;
}
.cursor-pointer {
  cursor: pointer;
}

.drop-text-container {
  border-top: 1px solid #f1f1f1;
}
</style>